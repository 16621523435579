import React, { useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { useContactPageEntry } from '@hooks/cms';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import VisibilitySensor from '@c/VisibilitySensor';
import { Box, Container, Grid } from '@material-ui/core';
import FormHandler from '@c/forms';
import { transparentize } from 'polished';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    color: transparentize(0.32, theme.colors.dark),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.625rem',
      lineHeight: 1.2,
      letterSpacing: '0.4px',
    },
  },
  text: {
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const ContactPage = () => {
  const classes = useStyles();
  const theme = useTheme(),
    [, setBackground] = useColorMode(),
    contactPageCmsEntry = useContactPageEntry(),
    contactPageEntry = contactPageCmsEntry.entry,
    seomatic = contactPageCmsEntry.seomatic;

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        header: { mode: 'light' },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg]
  );

  return (
    <EntryContextProvider entry={contactPageEntry}>
      <Layout seomatic={seomatic}>
        <VisibilitySensor id='kontakt-oss' onVisible={handleOnVisible}>
          <Box mt={{ xs: 16, md: 34 }} mb={{ xs: 5, md: 16 }}>
            <Container>
              <Grid container justify='center'>
                <Grid item xs={12} md={8} lg={6}>
                  <Box mb={{ xs: 8, md: 16 }}>
                    <h1>{contactPageEntry.title}</h1>
                  </Box>

                  <FormHandler
                    form={contactPageEntry.contactForm[0].form}
                    contactFormCta={contactPageEntry.contactForm[0].contactFormCta}
                  />

                  {!!contactPageEntry.contactText.length && (
                    <Box mt={{ xs: 6, md: 12 }}>
                      <Grid container>
                        {contactPageEntry.contactText?.map(contactItem => (
                          <Grid item xs={12} md={6} key={contactItem.contactTitle}>
                            <Box
                              className={classes.title}
                              dangerouslySetInnerHTML={{ __html: contactItem.contactTitle }}
                            />
                            <Box
                              className={classes.text}
                              mb={{ xs: 4, md: 0 }}
                              dangerouslySetInnerHTML={{ __html: contactItem.contactInfo }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </VisibilitySensor>
      </Layout>
    </EntryContextProvider>
  );
};

export default ContactPage;
